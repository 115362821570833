exports.handleSmoothScroll = (e, target, TargetOffset) => {
    e.preventDefault();
    const element = document.querySelector(target);
    const offset = TargetOffset; // Adjust the offset as needed
    const topPos = element.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
      top: topPos,
      behavior: 'smooth',
    });
  };

  exports.handleScrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };