import React from "react";
import Hero from "../components/Hero";
import Tjenester from "../components/Tjenester";
import Kontaktskjema from "../components/Kontaktskjema";
import Omoss from "../components/Omoss";

function HomePage() {
  return (
    <div>
      <Hero />
      <Tjenester/>
      <Omoss />
      <Kontaktskjema />
    </div>
  );
}

export default HomePage;
