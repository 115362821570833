import React, { useState } from 'react';
import { PiMapPinLineDuotone } from "react-icons/pi";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";

const Kontaktskjema = () => {
  const [formData, setFormData] = useState({
    company: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData); // Log the form data for testing
    try {
      const response = await fetch('/submit-contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      document.getElementById('success-message').style.display = 'block'; // Vis suksessmelding
      document.getElementById('submit-button').style.display = 'none'; // Vis suksessmelding

      if (response.ok) {
        // Request was successful
        // console.log('Form data submitted successfully!');
        // document.getElementById('success-message').style.display = 'block'; // Vis suksessmelding
        // document.getElementById('submit-button').style.display = 'none'; // Vis suksessmelding
      } else {
        // Request failed
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    // Here you can add your logic to submit the form data to the server
  };

  return (
    <div id='kontakt' className="body-font relative text-gray-400 min-h-screen content-section">

      <div className="container mx-auto px-5 py-8">

        <div className="mb-6 flex w-full flex-col text-center">
          <h1 className="title-font mb-4 text-2xl font-medium text-white sm:text-3xl">Kontakt oss</h1>
          <p className='text-center border-b-2 border-[#16E0B2]  mb-5 mx-[25%] '></p>
          <p className="mx-auto text-base leading-relaxed lg:w-2/3">Har du spørsmål angående våre tjenester, priser eller annet? <br />Ta gjerne kontakt for en uforpliktende prat, eller send oss en e-post med dine spørsmål.
          </p>
        </div>
        <ul className='mb-10 flex place-items-center flex-wrap place-content-center'>
          <div className='mx-auto sm:flex'>
            <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><PiMapPinLineDuotone /></h1>&nbsp; <a href="https://www.google.com/maps/place/Storemyrlia+29,+4790+Lillesand/@58.2718729,8.3219323,575m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4647f9c4cdeac8b5:0x6056c84b393e25b0!8m2!3d58.2718701!4d8.3245072!16s%2Fg%2F11c4ym27x0?hl=no&entry=ttu&g_ep=EgoyMDI0MTIwOS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">Storemyrlia 29, 4790 Lillesand</a></li>
            <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><FaPhone /></h1>&nbsp;<a href="tel:+47 984 45 830">+47 984 45 830</a></li>
            <li className='flex justify-start mx-4 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><IoMail /></h1>&nbsp; <a className='hover:text-blue-700 underline underline-offset-1' href="mailto:mail@lillesandit.no">mail@lillesandit.no</a></li>
          </div>
        </ul>
        <form onSubmit={handleSubmit}>
          <div className="mx-auto md:w-2/3 lg:w-1/2">
            <div className="-m-2 flex flex-wrap">

              <div className="w-1/2 p-2">
                <div className="relative">
                  <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="" />
                  <label for="name" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-indigo-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-indigo-500">Epost</label>
                </div>
              </div>
              <div className="w-1/2 p-2">
                <div className="relative">
                  <input type="phone" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="" />
                  <label for="email" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-indigo-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-indigo-500">Telefon</label>
                </div>
              </div>
              <div className="mt-4 w-full p-2">
                <div className="relative">
                  <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="peer h-32 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                  <label for="message" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-indigo-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-indigo-500">Melding</label>
                </div>
              </div>
              <div className="w-full p-2">
                <button type="submit" id='submit-button' className="text-black mb-10 bg-[#16E0B2] hover:scale-105 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#16E0B2] dark:hover:scale-105">Send forespørsel</button>
                <h1 id='success-message' className='hidden text-black mb-10 bg-[#16e0b1ae] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#16e0b1ae]'>Forespørsel er sendt, vi vil ta kontakt så fort som mulig</h1>
              </div>
            </div>
          </div>

        </form>
        <div className="w-full p-2 pt-8 text-center">
          <div className='w-full h-[27.9vh] flex items-start justify-center flex-grow'>
            {/* <iframe className='w-full h-full fill rounded-3xl' title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.1406438694344!2d8.364830529527827!3d58.25210596524979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4647fa23f6fac79b%3A0xc1ac3196fd712de8!2sBrogata%201%2C%204790%20Lillesand!5e1!3m2!1sno!2sno!4v1710276778397!5m2!1sno!2sno"></iframe> */}
            <iframe className='w-full h-full fill rounded-3xl' title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2505.275691501011!2d8.321932277229457!3d58.27187289521168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4647f9c4cdeac8b5%3A0x6056c84b393e25b0!2sStoremyrlia%2029%2C%204790%20Lillesand!5e1!3m2!1sno!2sno!4v1734009015087!5m2!1sno!2sno" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

      </div>

    </div>
  );
}

export default Kontaktskjema;
