import React from 'react';
import { ReactTyped } from "react-typed";
import { handleSmoothScroll } from '../js/smoothScroll'
import { PiMapPinLineDuotone } from "react-icons/pi";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";




// const LOGOS = [
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
//   <img src='https://lillesandelektro.no/wp-content/uploads/2018/Logo.png' alt="" width={100} height={100} />,
  
// ];

const Hero = () => {
  return (
    <div id="top" className="text-white bg-[url('/src/assets/bakgrunn.jpg')] bg-fixed bg-cover bg-bottom bg-no-repeat content-section">
      <div className="w-full min-h-screen mx-auto flex text-center flex-col pt-[10%] md:pt-0 md:justify-center flex-wrap text-wrap">
        <div className="flex flex-col self-center h-fit mx-2 md:w-[60%] rounded-3xl backdrop-blur-xl shadow-inner shadow-[#77a2ff] drop-shadow-2xl backdrop-opacity-75 pb-10">
          <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold py-6 flex-wrap text-wrap'>Gjør det enkelt</h1>
          <div className='justify-center items-center font-bold'>
            <p className='md:text-5xl sm:text-4xl text-xl font-bold'>Vi leverer tjenester som</p>
            <ReactTyped className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2' strings={[
              "nettsider",
              "support",
              "on-site",
              "SPOC",
              "nettverk",
              "innkjøp",
            ]} typeSpeed={120} backSpeed={140} loop />
          </div>
          <p className='md:text-2xl text-xl font-bold text-gray-300 mt-4 flex-wrap text-wrap'>Vi tar oss tid til å lære dine IT behov slik at du slipper.<br></br> Spar tid og få en mer effektiv hverdag for deg og dine ansatte.</p>
          <ul className='flex place-items-center flex-wrap place-content-center'>
            <div className='mx-auto my-10 sm:flex'>
              <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><PiMapPinLineDuotone /></h1>&nbsp; <a href="https://www.google.com/maps/place/Storemyrlia+29,+4790+Lillesand/@58.2718729,8.3219323,575m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4647f9c4cdeac8b5:0x6056c84b393e25b0!8m2!3d58.2718701!4d8.3245072!16s%2Fg%2F11c4ym27x0?hl=no&entry=ttu&g_ep=EgoyMDI0MTIwOS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">Storemyrlia 29, 4790 Lillesand</a></li>
              <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><FaPhone /></h1>&nbsp;<a href="tel:+47 984 45 830">+47 984 45 830</a></li>
              <li className='flex justify-start mx-4 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><IoMail /></h1>&nbsp; <a className='hover:text-blue-700 underline underline-offset-1' href="mailto:mail@lillesandit.no">mail@lillesandit.no</a></li>
            </div>
          </ul>
        </div>
        <a href='#kontakt' onClick={(e) => handleSmoothScroll(e, '#kontakt', 50)} className='bg-[#16E0B2] hover:scale-105 w-[200px] rounded-md font-medium my-6 mx-auto p-3 text-black'>Ta kontakt</a>
        {/* <div className="relative backdrop-blur-xl min-h-20 mt-auto mb-24 shadow-inner shadow-[#77a2ff] drop-shadow-2xl backdrop-opacity-95 rounded-3xl mx-auto w-[95%] md:w-[60%] overflow-hidden before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(100%*10)] py-10">
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[125px] items-center justify-center mr-5"
              key={index}
            >
              {logo}
            </div>
          ))}
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[125px] items-center justify-center mr-5"
              key={index}
            >
              {logo}
            </div>
          ))}
        </div>
      </div> */}
      </div>
      <div>
      </div>
    </div>
  )
}

export default Hero