import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Landing";
import WebBestilling from "./pages/WebBestilling";
import NotFoundPage from "./pages/Landing"; // Corrected import for NotFoundPage
import Footer from "./components/Footer";
import Header from "./components/Header";
import UpButton from "./components/UpButton";

function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gradient-to-tl from-[#312b987b] to-[#08181b]">
        <Header />
        <UpButton/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/WebSkjema" element={<WebBestilling />} />
          {/* <Route path="/about" element={<AboutPage />} />
          <Route path="/kontakt" element={<KontaktPage />} /> */}
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
