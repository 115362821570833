import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo-no-background.png';
import { handleScrollToTop, handleSmoothScroll } from '../js/smoothScroll';
import { motion, AnimatePresence } from 'framer-motion';
import Hamburger from 'hamburger-react';

const Header = () => {
  const [nav, setNav] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const bodyRef = useRef(document.body);
  const location = useLocation();

  const handleNav = () => {
    setNav(!nav);
    if (!nav) {
      bodyRef.current.style.overflow = 'hidden'; // Prevent scrolling when menu is open
    } else {
      bodyRef.current.style.overflow = ''; // Allow scrolling when menu is closed
    }
  };

  const handleResize = () => {
    // Close the mobile menu when transitioning to a larger screen size
    if (window.innerWidth >= 768) {
      setNav(false);
      bodyRef.current.style.overflow = ''; // Allow scrolling when menu is closed due to resize
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const firstSection = document.querySelector('.content-section');
    if (firstSection) {
      setActiveMenu(firstSection.id);
    }

    const handleScroll = () => {
      const sections = document.querySelectorAll(".content-section");

      sections.forEach((section) => {
        const top = section.offsetTop - 150; // Adjust offset as needed
        const bottom = top + section.clientHeight;
        const scrollPosition = window.scrollY;

        if (scrollPosition >= top && scrollPosition < bottom) {
          setActiveMenu(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className='min-w-full min-h-fit sticky top-0 z-50 border-gray-700 bg-[#0f1520] bg-opacity-95'>
      <div className='grid grid-cols-2'>
        <div className='p-5 pl-[10%] flex place-items-center'>
          {!nav && location.pathname.startsWith('/') && location.pathname !== "/webskjema" ? <Link to='/'><img className='mr-2' onClick={handleScrollToTop} src={Logo} alt='/logo' width={'200px'} /></Link> : <Link to='/'><img src={Logo} alt='/logo' width={location.pathname === "/webskjema" ? '200px' : '250px'} /></Link>}
          {/* {!nav ? <Link to='/'><img onClick={handleScrollToTop} src={LogoName} alt='/logo' width={'250px'} /></Link> : <Link to='/'><img src={LogoName} alt='/logo' width={'250px'} /></Link>} */}
        </div>
        <div className={`hidden md:flex justify-end self-center ${location.pathname === "/webskjema" && 'md:hidden'}`}>
          <ul className='hidden md:flex text-white pr-[10%] text-nowrap'>
            <li className={`p-4 hover:text-[#16E0B2] ${activeMenu === 'top' ? 'text-[#16E0B2]' : ''}`}>
              <a href='#home' onClick={handleScrollToTop}>Hjem</a>
            </li>
            <li className={`p-4 hover:text-[#16E0B2] ${activeMenu === 'tjenester' ? 'text-[#16E0B2]' : ''}`}>
              <a href='#tjenester' onClick={(e) => handleSmoothScroll(e, '#tjenester', 30)}>Våre tjenester</a>
            </li>
            <li className={`p-4 hover:text-[#16E0B2] ${activeMenu === 'omoss' ? 'text-[#16E0B2]' : ''}`}>
              <a href='#omoss' onClick={(e) => handleSmoothScroll(e, '#omoss', 100)}>Om oss</a>
            </li>
            <li className={`p-4 hover:text-[#16E0B2] ${activeMenu === 'kontakt' ? 'text-[#16E0B2]' : ''}`}>
              <a href='#kontakt' onClick={(e) => handleSmoothScroll(e, '#kontakt', 50)}>Kontakt</a>
            </li>
          </ul>
        </div>
        <div className={`hidden md:flex justify-end self-center ${location.pathname !== "/webskjema" && 'md:hidden'}`}>
          <ul className='hidden md:flex text-white pr-[10%] text-nowrap'>
            <li className={`p-4 hover:text-[#16E0B2] ${activeMenu === 'top' ? 'text-[#16E0B2]' : ''}`}>
              <Link to='/'>Hjem</Link>
            </li>
          </ul>
        </div>

        <div id='hamburger-button' className='flex md:hidden self-center justify-end w-fit ml-auto mr-[5%] hover:scale-105 text-white' onClick={handleNav}>
          <Hamburger toggled={nav} toggle={setNav} />
        </div>
      </div>
      {nav && (
        <AnimatePresence>
          <motion.div
            key='mobile-menu'
            className='min-w-full min-h-screen fixed right-0 ml-auto rounded-b-3xl backdrop-blur-3xl backdrop-opacity-100 backdrop-contrast-125'
            initial={{ opacity: 0, y: -0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -0 }}
            transition={{ type: "spring", duration: 1 }}
          >
            <div className={`min-w-full min-h-screen flex flex-col items-center justify-start pt-[10%] text-center ${location.pathname === "/webskjema" && 'hidden'}`}>
              <ul className='text-white'>
                <li onClick={handleNav} className={`p-4 border-b border-[#16E0B2] hover:text-[#16E0B2] hover:scale-105 duration-300 ${activeMenu === 'top' ? 'text-[#16E0B2]' : ''}`}>
                  <a href='#home' onClick={handleScrollToTop}>Hjem</a>
                </li>
                <li onClick={handleNav} className={`p-4 border-b border-[#16E0B2] hover:text-[#16E0B2] hover:scale-105 duration-300 ${activeMenu === 'tjenester' ? 'text-[#16E0B2]' : ''}`}>
                  <a href='#tjenester' onClick={(e) => handleSmoothScroll(e, '#tjenester', 25)}>Våre tjenester</a>
                </li>
                <li onClick={handleNav} className={`p-4 border-b border-[#16E0B2] hover:text-[#16E0B2] hover:scale-105 duration-300 ${activeMenu === 'omoss' ? 'text-[#16E0B2]' : ''}`}>
                  <a href='#omoss' onClick={(e) => handleSmoothScroll(e, '#omoss', 100)}>Om oss</a>
                </li>
                <li onClick={handleNav} className={`p-4 hover:text-[#16E0B2] border-[#16E0B2] hover:scale-105 duration-300 ${activeMenu === 'kontakt' ? 'text-[#16E0B2]' : ''}`}>
                  <a href='#kontakt' onClick={(e) => handleSmoothScroll(e, '#kontakt', 50)}>Kontakt</a>
                </li>
              </ul>
            </div>
            <div className={`min-w-full min-h-screen flex flex-col items-center justify-start pt-[10%] text-center ${location.pathname !== "/webskjema" && 'hidden'}`}>
              <ul className='text-white'>
                <li onClick={handleNav} className={`p-4 border-b border-[#16E0B2] hover:text-[#16E0B2] hover:scale-105 duration-300 ${activeMenu === 'top' ? 'text-[#16E0B2]' : ''}`}>
                  <Link to='/'>Hjem</Link>
                </li>
              </ul>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default Header;
