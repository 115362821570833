import React from "react";
import Fredrik from "../assets/Fredrik.png"
import Michael from "../assets/Michael.png"
import { PiMapPinLineDuotone } from "react-icons/pi";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";

const Omoss = () => {
  return (
    <div id="omoss" className="content-section">
      <div className="my-[10%]">
        <div className="w-[100%] h-fit flex justify-items-center place-content-center">
          <div className="md:w-[70%] h-fit mx-[5%] shadow-inner shadow-[#77a2ff] text-white drop-shadow-2xl backdrop-blur-3xl backdrop-opacity-75 rounded-3xl">
            <h1 className="text-center text-white text-7xl font-extralight">Om oss</h1>
            <p className='text-center border-b-2 border-[#16E0B2] mt-5 mb-5 mx-[25%] '></p>
            <div>
              <p className="center text-white font-light flex flex-wrap px-10 pt-5 mb-10 ">
                Vi er stolte av å være din lokale IT-leverandør i Lillesand. Hos oss er det ikke bare teknologi som står i sentrum, men også vårt engasjement for det lokale næringslivet. Vi tror på å bygge sterke relasjoner med våre kunder og samfunnet rundt oss, og vi er lidenskapelig opptatt av å støtte og hjelpe lokale bedrifter med deres IT-behov. <br></br><br />
                Som en del av Lillesand-samfunnet, vet vi hvor viktig det er å ha pålitelige IT-løsninger som fungerer sømløst og effektivt. Derfor streber vi etter å levere skreddersydde løsninger som passer dine behov og budsjett, slik at du kan fokusere på det som virkelig betyr noe - å drive din bedrift.<br></br><br />
                Vårt team er her for å hjelpe deg med alt fra nettstedutvikling og nettverksvedlikehold til IT-støtte og rådgivning. Vi er dedikert til å levere høykvalitets tjenester og support, slik at du kan være trygg på at din IT-infrastruktur er i gode hender.
              </p>
            </div>
          </div>

        </div>
        <div className="justify-items-center place-content-center md:flex flex-row mt-[5%]">
          <div className="md:w-[50%] rounded-3xl md:rounded-tl-[10rem] md:rounded-l-[4rem] pt-2 mx-[5%] md:rounded-r-[2rem] shadow-inner shadow-[#77a2ff] text-white drop-shadow-2xl backdrop-blur-3xl backdrop-opacity-75">
            <div className="w-[150px] h-[150px] shadow-lg shadow-[#7fc0fd] mx-auto mt-5 rounded-full overflow-hidden">
              <img src={Fredrik} className="translate-x-2" alt="" />
            </div>
            <div className="w-full h-full">
              <div className="w-full h-full ">
                <h1 className="text-white text-center text-2xl mt-3">Fredrik Johansen</h1>
                <p className='text-center border-b-2 border-[#16E0B2] mt-5 mb-5 mx-[25%]'></p>
                <ul className='mb-10 flex place-items-center flex-wrap place-content-center'>
                  <div className='mx-auto sm:flex'>
                    <li className='flex justify-start mx-4 text-slate-300 mb-2 md:mb-0'><h1 className='flex place-items-center text-[#16E0B2]'><IoMail /></h1>&nbsp; <a className='hover:text-blue-700 underline underline-offset-1' href="mailto:Fredrik@lillesandit.no">Fredrik@lillesandit.no</a></li>
                    <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><FaPhone /></h1>&nbsp;<a href="tel:+47 988 62 911">+47 988 62 911</a></li>
                  </div>
                </ul>
                <p className="text-white mt-3 mx-5 pb-10 font-light">
                  Jeg er virkelig glad i alt som har med teknologi å gjøre. Fra å fikle med min første maskin som 6-åring til å jobbe med komplekse IT-løsninger som voksen, har interessen min bare økt med tiden. <br /> <br />
                  Jeg trives i den skiftende verdenen av teknologi, hvor hver dag byr på nye utfordringer og muligheter. Å løse problemer og finne kreative løsninger er det som virkelig motiverer meg. <br /> <br />
                  Når jeg ikke er på jobb, liker jeg å utforske ny teknologi og lære forskjellige programmeringsspråk. For meg er teknologi ikke bare arbeid, det er en del av livet mitt.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-10 md:mt-0 md:w-[50%] rounded-3xl md:rounded-tr-[10rem] md:rounded-r-[4rem] pt-2 mx-[5%] md:rounded-l-[2rem] shadow-inner shadow-[#77a2ff] text-white drop-shadow-2xl backdrop-blur-3xl backdrop-opacity-75">
            <div className="w-[150px] h-[150px] shadow-lg shadow-[#7fc0fd] mx-auto mt-5 rounded-full overflow-hidden">
              <img src={Michael} className="size-" alt="" />
            </div>
            <div className="w-full h-full">
              <div className="w-full h-full">
                <h1 className="text-white text-center text-2xl mt-3">Michael Oldeide</h1>
                <p className='text-center border-b-2 border-[#16E0B2] mt-5 mb-5 mx-[25%]'></p>
                <ul className='mb-10 flex place-items-center flex-wrap place-content-center'>
                  <div className='mx-auto sm:flex'>
                    <li className='flex justify-start mx-4 text-slate-300 mb-2 md:mb-0'><h1 className='flex place-items-center text-[#16E0B2]'><IoMail /></h1>&nbsp; <a className='hover:text-blue-700 underline underline-offset-1' href="mailto:Michael@lillesandit.no">Michael@lillesandit.no</a></li>
                    <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><FaPhone /></h1>&nbsp;<a href="tel:+47 984 45 830">+47 984 45 830</a></li>
                  </div>
                </ul>
                <p className="text-white mt-3 mx-5 pb-0 font-light">
                  Å følge med på utviklingen i IT teknologien er noe av det mest interessante i arbeidshverdagen. Det motiverer meg stort å kunne løse problemer og finne nye og innovative løsninger. Bidra til en enklere hverdag for andre ved hjelp av ny teknologi er utrolig spennende for meg. <br></br><br />
                  Jeg stolt over å kunne tilby mine ferdigheter og erfaring til våre kunder. <br></br><br />
                  På fritiden liker jeg å dykke dypere i den nyeste teknologien, enten det er å eksperimentere med AI og maskinlæring, utforske skybaserte tjenester, eller holde på med egne servere hjemme.<br></br><br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Omoss;
