import React from 'react';
import {
    FaRegCopyright
} from 'react-icons/fa';

const Footer = () => {
    return (
        <div className='text-gray-300 mt-auto'>
            <div className='grid grid-cols-3 gap-8 mx-auto bg-gray-800 py-5'>
                <div className='w-full flex justify-center self-center flex-wrap'>
                    {/* <a className="hover:scale-110" href="https://www.facebook.com/lillesandit" target="_blank"><FaFacebookSquare className='mx-5' size={30} /></a>
                    <a className="hover:scale-110" href="https://www.instagram.com/lillesandit" target="_blank"><FaInstagram className='mx-5' size={30} /></a>
                    <a className='hover:scale-110' href="https://twitter.com/lillesandit" target="_blank"><FaTwitterSquare className='mx-5' size={30} /></a> */}
                </div>
                <div className='flex justify-center self-center'>
                    <h1 className='text-nowrap flex-nowrap flex text-xs'><FaRegCopyright className='justify-center self-center flex' />&nbsp; <a href="https::/">Utviklet av Lillesand IT</a></h1>
                </div>
            </div>
        </div>
    );
}

export default Footer;
