import React, { useState, useRef } from 'react';
import { PiMapPinLineDuotone } from "react-icons/pi";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { handleScrollToTop } from "../js/smoothScroll";


export default function WebBestilling() {
  const [currentStep, setCurrentStep] = React.useState(0);
  const numberOfSteps = 5;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const recaptchaRef = useRef(null);
  const [captchaStatus, setCaptchaStatus] = useState(true);

  const [isInvalid, setIsInvalid] = useState({
    navn: false,
    telefon: false,
    email: false,
    company: false,
    failed: false,
  });
  const [formData, setFormData] = useState({
    company: '',
    navn: '',
    telefon: '',
    email: '',
    undersider: 1,
    bilder: false,
    logo: false,
    innholdstekst: false,
    filer: 0,
    hovedfarge: '',
    sekundærfarge: '',
    tredjefarge: '',
    darkmode: false,
    kommentar: '',
    underside1: 'Hvordan skal hovedsiden se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside2: 'Hvordan skal side 2 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside3: 'Hvordan skal side 3 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside4: 'Hvordan skal side 4 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside5: 'Hvordan skal side 5 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside6: 'Hvordan skal side 6 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside7: 'Hvordan skal side 7 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside8: 'Hvordan skal side 8 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside9: 'Hvordan skal side 9 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
    underside10: 'Hvordan skal side 10 se ut?\n• Bilder?\n• Ønsket oppsett / oppdeling\n• Skjema\n• Eksempel nettsider',
  });
  var checkboxs = [
    'logo',
    'bilder',
    'innholdstekst',
    'darkmode'
  ]

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (checkboxs.includes(name)) {
      if (formData[name]) {
        setFormData(prevState => ({
          ...prevState,
          [name]: false
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          [name]: true
        }));
      }
      return;
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setCaptchaStatus(false);
      return;
    } else {
      setCaptchaStatus(true);
    }
    const response = await axios.post('/submit-captcha', {
      recaptchaToken: recaptchaValue,
    });
    if (!response || response.data.message !== 'reCAPTCHA submitted successfully.') {
      alert('Vennligst vent til CAPTCHA utløper eller refresh siden og prøv igjen.')
      return;
    }
    const checkUploads = await handleFileUpload()
    if (checkUploads === false) {
      setCurrentStep(2);
      return;
    }

    goToNextStep()
    try {
      const response = await fetch('/submit-web-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Request was successful
        // console.log('Form data submitted successfully!');
        // document.getElementById('success-message').style.display = 'block'; // Vis suksessmelding
        // document.getElementById('submit-button').style.display = 'none'; // Vis suksessmelding
      } else {
        // Request failed
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    // Here you can add your logic to submit the form data to the server
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(event.target.files);
    setUploadStatus('');
    setUploadProgress(0);
    setFormData((prevFormData) => ({
      ...prevFormData,
      filer: files.length,
    }));
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      // setUploadStatus('Vennligst velg minst en fil.');
      return true
    }
    if (!formData.company) {
      setUploadStatus('Vennligst fyll ut firma / nettside informasjon på ');
      return false
    }
  
    const formDataFile = new FormData();
    formDataFile.append('companyName', formData.company); // Add company name to form data
  
    Array.from(selectedFiles).forEach(file => {
      formDataFile.append('files', file);
    });
  
    try {
      const response = await axios.post('/uploadFile', formDataFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      setUploadStatus('Bildene er blitt lasted opp!');
      return true
    } catch (error) {
      setUploadProgress(-1)
      setUploadStatus('Det har skjedd en feil, vennligst prøv igjen.');
      console.error('Error uploading files:', error);
      return false
    }
  };

  function GoToStep1() {
    setUploadStatus('');
    setCurrentStep(0);
  }  

  const goToNextStep = (e) => {
    handleScrollToTop(e)
    if (currentStep === 0) {
      if (!formData.navn || !formData.email || !formData.telefon || !formData.company) {
            const newInvalidState = {
            failed: true
          };

          setIsInvalid(newInvalidState);
          return;
      }
    }
    setCurrentStep((prev) => (prev === numberOfSteps ? prev : prev + 1));
  }
  const goToPreviousStep = () =>
    setCurrentStep((prev) => (prev <= 0 ? prev : prev - 1));

  const activeColor = (index) =>
    currentStep >= index ? "bg-[#16E0B2] duration-1000" : "bg-gray-300 duration-1000";

  return (
    <div className="">
        <div className="mt-6 flex w-full flex-col text-center">
          <h1 className="title-font text-2xl font-medium text-white sm:text-3xl">Bestillingsskjema for ny nettside </h1>
          {/* <p className='text-center border-b-2 border-[#16E0B2]  mb-5 mx-[25%] '></p> */}
          {/* <p className="mx-auto text-base leading-relaxed lg:w-2/3">Har du spørsmål angående våre tjenester, priser eller annet? <br />Ta gjerne kontakt for en uforpliktende prat, eller send oss en e-post med dine spørsmål.
          </p> */}
        </div>
    <div className="grid grid-col place-items-center mx-3 sm:mx-5 md:mx-10">
        
      <div className="flex items-center mt-10">
      <span className="h-1 w-8 sm:w-14 md:w-24 xl:w-44 2xl:w-52 bg-[#16E0B2]"></span>

        {Array.from({ length: numberOfSteps }).map((_, index) => (
          <React.Fragment key={index}>
            <div className={`w-6 h-6 rounded-full ${activeColor(index)}`}><h1 className='text-center'>{index+1}</h1></div>
            {currentStep === numberOfSteps-1 ? (<div className={`w-8 sm:w-14 md:w-24 xl:w-44 2xl:w-52 h-1 ${activeColor(index+1)}`}></div>) : (<div className={`w-8 sm:w-14 md:w-24 xl:w-44 2xl:w-52 h-1 ${activeColor(index+1)}`}></div>)}
          </React.Fragment>
        ))}
      </div>
      <div className="mt-10 min-h-60 min-w-full">
        <div className="">
        <form onSubmit={handleSubmit}>
          <div className="mx-auto md:w-2/3 lg:w-1/2">
            <div className="-m-2 flex flex-wrap">

              <div className={`w-full p-2 flex flex-col mt-5 ${currentStep === 0 ? 'block' : 'hidden'}`}>
                <h1 className='text-white text-center mb-10 text-xl'>Legg inn kontaktinformasjonen din</h1>
                <div className="relative w-full xl:w-1/2 sm:w-2/3 self-center">
                  <input type="text" id="navn" name="navn" value={formData.navn} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="" required />
                  <label for="navn" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Navn</label>
                  {isInvalid.failed && !formData.navn && (<p className="mt-2 text-sm text-red-500">Navn er påkrevd.</p>)}
                </div>
                <div className="relative w-full mt-10  xl:w-1/2 sm:w-2/3 self-center">
                  <input type="tel" id="telefon" name="telefon" value={formData.telefon} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="" required />
                  <label for="number" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Telefonnummer</label>
                  {isInvalid.failed &&!formData.telefon && (<p className="mt-2 text-sm text-red-500">Telefonnummer er påkrevd.</p>)}
                </div>
                <div className="relative w-full mt-10  xl:w-1/2 sm:w-2/3 self-center">
                  <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="" required />
                  <label for="email" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Epost</label>
                  {isInvalid.failed && !formData.email && (<p className="mt-2 text-sm text-red-500">Epost er påkrevd.</p>)}
                </div>
                <div className="relative mt-10 w-full xl:w-1/2 sm:w-2/3 self-center">
                  <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder="" required />
                  <label for="company" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Firma / Nettaddresse</label>
                  {isInvalid.failed && !formData.company && (<p className="mt-2 text-sm text-red-500">Firmanavn er påkrevd.</p>)}
                </div>
              </div>
              <div className={`w-full p-2 mt-5 ${currentStep === 1 ? 'block' : 'hidden'}`}>
              <h1 className='text-white text-center mb-10 text-xl'>Velg hvor mange sider du ønsker og forklar hvordan du vil at de ser ut</h1>
                <div className="relative">
                  <input type="range" id="undersider" name="undersider" min={1} max={10} value={formData.undersider} onChange={handleChange} className="peer w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out accent-[#16E0B2]" placeholder="" />
                  <label for="undersider" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-white transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 ">Antall sider: {formData.undersider }</label>
                </div>
                <div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 1 ? 'block' : 'hidden'}`}>
                        <textarea id="underside1" name="underside1" value={formData.underside1} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=''></textarea>
                        {/* <label for="underside1" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal hovedsiden se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 2 ? 'block' : 'hidden'}`}>
                        <textarea id="underside2" name="underside2" value={formData.underside2} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside2" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 2 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 3 ? 'block' : 'hidden'}`}>
                        <textarea id="underside3" name="underside3" value={formData.underside3} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside3" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 3 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 4 ? 'block' : 'hidden'}`}>
                        <textarea id="underside4" name="underside4" value={formData.underside4} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside4" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 4 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 5 ? 'block' : 'hidden'}`}>
                        <textarea id="underside5" name="underside5" value={formData.underside5} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside5" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 5 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 6 ? 'block' : 'hidden'}`}>
                        <textarea id="underside6" name="underside6" value={formData.underside6} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside6" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 6 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 7 ? 'block' : 'hidden'}`}>
                        <textarea id="underside7" name="underside7" value={formData.underside7} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside7" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 7 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 8 ? 'block' : 'hidden'}`}>
                        <textarea id="underside8" name="underside8" value={formData.underside8} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside8" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 8 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 9 ? 'block' : 'hidden'}`}>
                        <textarea id="underside9" name="underside9" value={formData.underside9} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside9" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 9 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                    <div className={`relative mt-10 ${currentStep === 1 ? 'block' : 'hidden'} ${formData.undersider >= 10 ? 'block' : 'hidden'}`}>
                        <textarea id="underside10" name="underside10" value={formData.underside10} onChange={handleChange} className="peer h-36 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                        {/* <label for="underside10" className="absolute left-3 -top-8 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Hvordan skal side 10 se ut?<br></br>• Bilder?<br />• Ønsket oppsett / oppdeling<br></br>• Skjema\n• Eksempel nettsider</label> */}
                    </div>
                </div>
              </div>
              <div className={`w-full p-2 place-items-center ${currentStep === 2 ? 'block' : 'hidden'}`}>
              <h1 className='text-white text-center mb-10 text-xl'>Trenger du nye bilder, logo eller en tekstforfatter til å lage teksten på nettsiden din?</h1>
                <div className='grid mb-5 md:mb-0 md:grid-cols-3'>
                    <div className="relative place-items-center grid">
                    <h1 className='text-white'>Trenger nye bilder </h1>
                    <input type="checkbox" id="bilder" name="bilder" value={formData.bilder} onChange={handleChange} placeholder="" className="mt-2 mb-7 md:mb-0 md:mt-10 relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-[#16E0B2] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-[#16E0B2] dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"/>
                    </div>
                    <div className="relative place-items-center grid">
                    <h1 className='text-white'>Trenger ny logo </h1>
                    <input type="checkbox" id="logo" name="logo" value={formData.logo} onChange={handleChange} placeholder="" className="mt-2 mb-7 md:mb-0 md:mt-10 relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-[#16E0B2] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-[#16E0B2] dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"/>
                    </div>
                    <div className="relative place-items-center grid">
                    <h1 className='text-white text-nowrap'>Trenger ny innholdstekst </h1>
                    <input type="checkbox" id="innholdstekst" name="innholdstekst" value={formData.innholdstekst} onChange={handleChange} placeholder="" className="mt-2 mb-7 md:mb-0 md:mt-10 relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-[#16E0B2] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-[#16E0B2] dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"/>
                    </div>
                </div>
              </div>
              <div className={`w-full p-2  place-items-center ${currentStep === 3 ? 'block' : 'hidden'}`}>
              <h1 className='text-white text-center mb-10 text-xl'>Velg fargene du ønsker å ha på nettsiden din og om du ønsker at vi gjør hele nettsiden «Dark mode» vennlig </h1>
                <div className='grid md:grid-cols-4'>
                    <div className="w-full mb-7 md:mb-0">
                    <div className="relative grid place-items-center">
                    <h1 className='text-white'>Hovedfarge</h1>
                    <input type="color" id="hovedfarge" name="hovedfarge" value={formData.hovedfarge} onChange={handleChange} className="w-16 h-16 bg-transparent" /></div>
                </div>
                <div className="w-full mb-7 md:mb-0">
                    <div className="relative grid place-items-center">
                    <h1 className='text-white'>Sekundærfarge</h1>
                    <input type="color" id="sekundærfarge" name="sekundærfarge" value={formData.sekundærfarge} onChange={handleChange} className="w-16 h-16 bg-transparent" /></div>
                </div>
                <div className="w-full mb-7 md:mb-0">
                    <div className="relative grid place-items-center">
                    <h1 className='text-white'>Alternativ farge</h1>
                    {/* <h1 className='text-white'>Legg til</h1> */}

                    <input type="color" id="tredjefarge" name="tredjefarge" value={formData.tredjefarge} onChange={handleChange} className="w-16 h-16 bg-transparent" /></div>
                </div>
                <div className="relative place-items-center grid">
                    <h1 className='text-white text-nowrap'>Dark mode </h1>
                    <input type="checkbox" id="darkmode" name="darkmode" value={formData.darkmode} onChange={handleChange} placeholder="" className="relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-[#16E0B2] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-[#16E0B2] dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"/>
                    </div>
                </div>
              </div>
              <div className={`mt-4 w-full p-2 ${currentStep === 4 ? 'block' : 'hidden'}`}>
              <h1 className='text-white text-center mb-10 text-xl'>Har du noen andre spørsmål eller tilleggsinfo så må du gjerne legge det ved her</h1>

                <div className="relative">
                  <textarea id="kommentar" name="kommentar" value={formData.kommentar} onChange={handleChange} className="peer h-32 w-full rounded border border-gray-500 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900" placeholder=""></textarea>
                  <label for="kommentar" className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-[#16E0B2] transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-[#16E0B2]">Tilleggs kommentar</label>
                </div>
              </div>
              <div className={`my-12 flex w-full flex-col text-center ${currentStep === 5 ? 'block' : 'hidden'}`}>
                <h1 className="title-font mb-4 text-2xl font-medium text-white sm:text-3xl">Skjema er sendt</h1>
                <p className='text-center border-b-2 border-[#16E0B2]  mb-5 mx-[25%] '></p>
                <p className="mx-auto text-base text-white leading-relaxed lg:w-2/3">Vi vil kontakte deg så fort som mulig.<br></br><br />Har du spørsmål angående våre tjenester, priser eller annet? <br />Ta gjerne kontakt for en uforpliktende prat, eller send oss en e-post med dine spørsmål.</p>
                <ul className='mb-10 flex place-items-center flex-wrap place-content-center'>
          <div className='mx-auto sm:flex mt-10'>
            <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><PiMapPinLineDuotone /></h1>&nbsp; <a href="https://www.google.com/maps?ll=58.252166,8.374965&z=15&t=h&hl=no&gl=NO&mapclient=embed&q=Brogata+1+4790+Lillesand" target="_blank" rel="noreferrer">Brogata 1, 4790 Lillesand</a></li>
            <li className='flex justify-start mx-4 mb-2 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><FaPhone /></h1>&nbsp;<a href="tel:+47 984 45 830">+47 984 45 830</a></li>
            <li className='flex justify-start mx-4 text-slate-300'><h1 className='flex place-items-center text-[#16E0B2]'><IoMail /></h1>&nbsp; <a className='hover:text-blue-700 underline underline-offset-1' href="mailto:mail@lillesandit.no">mail@lillesandit.no</a></li>
          </div>
        </ul>
            </div>
            </div>
          </div>
        </form>
        </div>
      </div>
      <div className={`flex flex-col items-center justify-center h-fit ${currentStep === 2 ? 'block' : 'hidden'}`}>
      <h1 className='text-white text-center mb-5 text-xl'>Har du logo eller bilder, kan det lastes opp nedenfor (maks 20)</h1>

      <div className={`mt-5 ${currentStep === 2 ? 'block' : 'hidden'}`}>
        {/* <h2 className="text-xl text-white mb-4 text-center">Last opp bilder</h2> */}
        <input
          type="file"
          name="files"
          multiple
          accept=".jpg,.jpeg,.png,.svg" // Specify the allowed file types
          onChange={handleFileChange}
          className="mb-4 w-full text-white px-3 py-2 border-[#16E0B2] accent-[#16E0B2] border rounded-md"
        />
        {uploadProgress > 0 && (
          <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
            <div
              className="bg-[#16E0B2] h-2 rounded-full duration-1000"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
        {uploadProgress < 0 && (
          <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
            <div
              className="bg-red-500 h-2 rounded-full duration-1000"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
        {/* <button
          onClick={handleFileUpload}
          className="w-full bg-[#16E0B2] text-black py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200"
        >
          Last opp
        </button> */}
        {uploadStatus && <p className="mt-4 text-yellow-500">{uploadStatus}{uploadStatus === 'Vennligst fyll ut firma / nettside informasjon på ' && <button onClick={GoToStep1} className={`bg-[#16E0B2] text-black flex-1 p-2 px-4 rounded-md mx-auto w-fit`}>Steg 1</button>}</p>}
      </div>
    </div>
        <div className={`mt-5 ${currentStep === 4 ? 'block' : 'hidden'}`}> <ReCAPTCHA sitekey={process.env.REACT_APP_KEY} ref={recaptchaRef} theme='dark' />
        {!captchaStatus && <p className="mt-2 text-sm text-red-500 text-center">Vennligst fullfør CAPTCHA</p>}
        </div>
      <div className="flex gap-10 my-5 text-nowrap">
        <button onClick={goToPreviousStep} className={`bg-[#16E0B2] text-black flex-1 p-2 px-4 rounded-md ${currentStep !== 0 && currentStep !== 5? 'block' : 'hidden'}`}>Forrige</button>
        <button onClick={goToNextStep} className={`bg-[#16E0B2] text-black flex-1 p-2 px-5 rounded-md ${currentStep < 4 ? 'block' : 'hidden'}`}>Neste</button>
        <button type="submit" id='submit-button' onClick={handleSubmit}  className={`text-black bg-[#16E0B2] flex-1 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#16E0B2] ${currentStep === 4 ? 'block' : 'hidden'}`}>Send skjema</button>
        <h1 id='success-message' className='hidden text-black mb-10 bg-[#16e0b1ae] flex-1 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#16e0b1ae]'>Skjema er sendt, vi vil ta kontakt så fort som mulig</h1>
        </div>
    </div>
    </div>
  );
}
