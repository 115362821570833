import React, { useState, useEffect, } from "react";
import { handleScrollToTop } from "../js/smoothScroll";
import { FaChevronCircleUp } from "react-icons/fa";

function Navbar() {
  const [showButton, setShowButton] = useState(false);



  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setShowButton(scrollPosition > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="text-white sticky top-0 z-10 opacity-90 border-b-2 border-opacity-50 border-gray-700 bg-[#0f1520]">
      <button
        onClick={handleScrollToTop}
        className={`fixed bottom-10 right-3 md:right-10 px-4 py-2 scale-150 md:scale-100 md:hover:scale-150 rounded-full transition duration-300 ease-in-out ${
          showButton ? "opacity-100" : "opacity-0"
        }`}
      >
        <FaChevronCircleUp size={30} />
      </button>
    </div>
  );
}

export default Navbar;
