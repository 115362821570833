import React from "react";
import WebBestilling from "../components/WebBestilling";

function HomePage() {

  return (
    <div>
      <WebBestilling/>
    </div>
  );
}

export default HomePage;
