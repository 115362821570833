import React from "react";

const Tjenester = () => {
  const liste = [
    {
      tittel: "Nettsider",
      undertittel:
        "La oss designe et unikt nettsted for bedriften din, som skiller seg ut og gir deg et konkurransefortrinn.",
      beskrivelse:
        "",
    },
    {
      tittel: "Support",
      undertittel:
        "Få tilgang til rask og pålitelig IT-support når du trenger det mest..",
      beskrivelse:
        "Vi står klare til å løse alle dine IT-relaterte problemer og spørsmål.",
    },
    {
      tittel: "On-site",
      undertittel: "Trenger du hjelp på stedet?",
      beskrivelse:
        "Vi kommer gjerne ut til bedriften din for å løse problemer og gi nødvendig støtte for å holde hverdagen i gang.",
    },
    {
      tittel: "SPOC",
      undertittel: "Ønsker du å kunne forholde deg til èn person?",
      beskrivelse:
        "Vår SPOC-tjeneste (Single Point of Contact) gir deg en dedikert kontaktperson hos oss som håndterer alle dine IT-behov, alt fra brukerstøtte til nettverksvedlikehold",
    },
    {
      tittel: "Nettverk",
      undertittel:
        "Bygg en solid nettverksinfrastruktur som støtter din virksomhets vekst og suksess.",
      beskrivelse:
        "Vi designer, implementerer og vedlikeholder nettverk som møter bedriftens behov og sikkerhetskrav.",
    },
    {
      tittel: "Innkjøp",
      undertittel:
        "Spar tid og penger ved å la oss håndtere dine IT-innkjøp.",
      beskrivelse:
        "Vi samarbeider med pålitelige leverandører for å sikre at du får de beste produktene til konkurransedyktige priser.",
    },
  ];
  function Tjenester() {
    return liste.map((m, index) => (
      <div
        className="rounded-3xl shadow-inner hover:scale-105 duration-500 shadow-[#77a2ff] text-white drop-shadow-2xl backdrop-blur-3xl backdrop-opacity-75"
        key={index}
      >
        <h1 className="text-3xl text-center mt-2">{m.tittel}</h1>
        <h1 className="border-b-2 border-[#16E0B2] mb-5 mx-[25%]">&nbsp;</h1>
        <p className="text-xl text-left px-5 pb-5 font-light">
          {m.undertittel} <br></br> <br />
          {m.beskrivelse}
        </p>
      </div>
    ));
  }
  return (
    <div id="tjenester" className="min-fit content-section">
      <h1 className="text-center text-white text-7xl font-extralight pt-[5rem]">
        Våre tjenester
      </h1>
      <h1 className="text-center border-b-2 border-[#16E0B2] mx-[25%]">&nbsp;</h1>
      <div className="flex flex-col mx-auto w-[80%] pb-20 md:pb-0">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 w-full md:px-8 lg:px-16 mt-10 px-[5%]">
          {Tjenester()}
        </div>
      </div>
    </div>
  );
};

export default Tjenester;
